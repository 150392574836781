var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.scenario &&
    ((_vm.selectedSites && _vm.selectedSites.length > 0 && !_vm.newRegion) ||
      (_vm.greenfieldLayers && _vm.greenfieldLayers.length && _vm.newRegion))
  )?_c('div',{staticClass:"create-scenario-from-sites d-flex justify-end align-center cursor-pointer"},[(_vm.scenario && _vm.selectedSites && _vm.selectedSites.length > 0 && !_vm.newRegion)?_c('div',{staticClass:"d-flex align-center"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"duplicate-icon",attrs:{"src":require("@/assets/icons/feasibility/duplicate.svg"),"alt":""},on:{"click":_vm.showCreateNewScenarioFromSitesModal}},'img',attrs,false),on))]}}],null,false,1692607589)},[_c('span',[_vm._v("Create new scenario from selected sites")])])],1):(
      _vm.scenario && _vm.greenfieldLayers && _vm.greenfieldLayers.length && _vm.newRegion
    )?_c('div',[_c('v-btn',{staticClass:"secondary",on:{"click":_vm.showCreateNewScenarioFromSitesModal}},[_c('span',[_vm._v("Create Scenario")])])],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }