<template>
  <div id="map"></div>
</template>

<script>
import Map from '@/services/map';
export default {
  mounted() {
    Map.initialise(this.centerLat, this.centerLon, this.zoomLevel);
  },
  computed: {
    scenario() {
      return this.$store.direct.state.Feasibility.scenario;
    },
    centerLat() {
      return this.$store.direct.state.Feasibility.centerLat;
    },
    centerLon() {
      return this.$store.direct.state.Feasibility.centerLon;
    },
    zoomLevel() {
      return this.$store.direct.state.Feasibility.zoomLevel;
    }
  },
  watch: {
    scenario: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          // Fly and Zoom to
          Map.flyTo(this.centerLat, this.centerLon, this.zoomLevel);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/_variables';

#map {
  height: calc(100vh - #{$nav-height});
  width: 100vw;
}
</style>
