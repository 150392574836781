var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sites-form"},[(_vm.getDataForSitesIsLoading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):(_vm.dataForSites && _vm.categories)?_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"mt-2"},_vm._l((_vm.categories[
          _vm.dataForSitesSelectedCategory
        ].subcategories),function(subcategory,subcategoryIndex){return _c('div',{key:'subcategories_' +
          subcategoryIndex +
          'categories_' +
          _vm.dataForSitesSelectedCategory,staticClass:"subcategory-box mb-4"},[_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"subcategory-text"},[_vm._v(_vm._s(subcategory.label))])]),_vm._l((subcategory.inputs),function(input,inputIndex){return _c('div',{key:'inputs_' +
            inputIndex +
            'subcategories_' +
            subcategoryIndex +
            'categories_' +
            _vm.dataForSitesSelectedCategory,staticClass:"d-flex justify-space-between align-center mb-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"input-text"},[_vm._v(_vm._s(input.label))]),(input.sublabel)?_c('span',{staticClass:"input-text"},[_vm._v(" "+_vm._s(input.sublabel)+" ")]):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('div',{class:[
                'small-text-field smaller-inputs',
                input.child ? 'mr-1' : ''
              ]},[_c('v-form',{attrs:{"onSubmit":"return false;"}},[(
                    _vm.dataForSites &&
                    _vm.dataForSites[input.name] !== undefined &&
                    input.type === 'select' &&
                    input.options
                  )?_c('v-select',{attrs:{"value":_vm.dataForSites[input.name],"items":input.options,"outlined":"","dense":"","hide-details":"","disabled":_vm.readOnly || input.readOnly},on:{"input":function($event){return _vm.updateDataForSites($event, input)}}}):(
                    _vm.dataForSites && _vm.dataForSites[input.name] !== undefined
                  )?_c('v-text-field',{attrs:{"value":_vm.dataForSites[input.name],"suffix":input.unit,"reverse":input.reverse,"rules":input.skipTextFieldRules ? [] : _vm.textFieldRules,"outlined":"","dense":"","hide-details":"auto","disabled":_vm.readOnly || input.readOnly},on:{"input":function($event){return _vm.updateDataForSites($event, input)}}}):_c('span',{staticClass:"red-text"},[_vm._v(" "+_vm._s(input.name)+" is undefined. ")])],1)],1),(input.child)?_c('div',{staticClass:"small-text-field smaller-inputs"},[(
                  _vm.dataForSites &&
                  _vm.dataForSites[input.name] !== undefined &&
                  input.child &&
                  input.child.name &&
                  _vm.dataForSites[input.child.name] !== undefined &&
                  input.child.type === 'select' &&
                  input.child.options
                )?_c('v-select',{attrs:{"value":_vm.dataForSites[input.child.name],"items":input.child.options,"outlined":"","dense":"","hide-details":"","disabled":_vm.readOnly || input.child.readOnly},on:{"input":function($event){return _vm.updateDataForSites($event, input.child)}}}):(input.child && input.child.name)?_c('span',{staticClass:"red-text"},[_vm._v(" "+_vm._s(input.child.name)+" is undefined. ")]):_vm._e()],1):_vm._e()])])})],2)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }