import { render, staticRenderFns } from "./MapV2.vue?vue&type=template&id=a471550c&scoped=true&"
import script from "./MapV2.vue?vue&type=script&lang=js&"
export * from "./MapV2.vue?vue&type=script&lang=js&"
import style0 from "./MapV2.vue?vue&type=style&index=0&id=a471550c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a471550c",
  null
  
)

export default component.exports