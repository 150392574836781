var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedSettings && _vm.categories)?_c('div',{staticClass:"planning-control-and-development-cost"},_vm._l((_vm.categories),function(category,categoryIndex){return _c('div',{key:'categories_' + categoryIndex,staticClass:"category-box mb-5 pa-3 pt-0"},[_c('div',{staticClass:"pa-2 pl-0 category-text-box d-flex justify-space-between align-center cursor-pointer",on:{"click":function($event){category.expand = !category.expand}}},[_c('span',{staticClass:"category-text"},[_vm._v(_vm._s(category.label))]),(category.expand)?_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/icons/feasibility/arrow-up.svg"),"alt":""}}):_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/icons/feasibility/arrow-down.svg"),"alt":""}})]),(category.expand)?_c('div',{staticClass:"mt-5"},_vm._l((category.subcategories),function(subcategory,subcategoryIndex){return _c('div',{key:'subcategories_' + subcategoryIndex + 'categories_' + categoryIndex,staticClass:"subcategory-box mb-4"},[_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"subcategory-text"},[_vm._v(_vm._s(subcategory.label))])]),_vm._l((subcategory.inputs),function(input,inputIndex){return _c('div',{key:'inputs_' +
            inputIndex +
            'subcategories_' +
            subcategoryIndex +
            'categories_' +
            categoryIndex,staticClass:"d-flex justify-space-between align-center mb-3"},[(input.type !== 'checkbox')?_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"input-text"},[_vm._v(_vm._s(input.label))]),(input.sublabel)?_c('span',{staticClass:"input-text"},[_vm._v(" "+_vm._s(input.sublabel)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"smaller-inputs mr-1"},[_c('v-form',{attrs:{"onSubmit":"return false;"}},[(
                    _vm.selectedSettings &&
                    _vm.selectedSettings[input.name] !== undefined &&
                    input.type === 'select' &&
                    input.options
                  )?_c('v-select',{staticClass:"small-text-field",attrs:{"value":_vm.selectedSettings[input.name],"items":input.options,"outlined":"","dense":"","hide-details":"","disabled":_vm.readOnly},on:{"input":function($event){return _vm.updateSelectedSettings($event, input)}}}):(
                    _vm.selectedSettings &&
                    _vm.selectedSettings[input.name] !== undefined &&
                    input.type === 'checkbox'
                  )?_c('v-checkbox',{attrs:{"label":input.label,"input-value":_vm.selectedSettings[input.name],"dense":"","hide-details":"","disabled":_vm.readOnly},on:{"change":function($event){return _vm.updateSelectedSettings($event, input)}}}):(
                    _vm.selectedSettings &&
                    _vm.selectedSettings[input.name] !== undefined
                  )?_c('v-text-field',{staticClass:"small-text-field",attrs:{"value":_vm.selectedSettings[input.name],"suffix":input.unit,"reverse":input.reverse,"rules":_vm.textFieldRules,"outlined":"","dense":"","hide-details":"auto","disabled":_vm.readOnly},on:{"input":function($event){return _vm.updateSelectedSettings($event, input)}}}):_c('span',{staticClass:"red-text"},[_vm._v(" "+_vm._s(input.name)+" is undefined. ")])],1)],1),(input.child)?_c('div',{staticClass:"small-text-field smaller-inputs mr-1"},[(
                  _vm.selectedSettings &&
                  _vm.selectedSettings[input.name] !== undefined &&
                  input.child &&
                  input.child.name &&
                  _vm.selectedSettings[input.child.name] !== undefined &&
                  input.child.type === 'select' &&
                  input.child.options
                )?_c('v-select',{attrs:{"value":_vm.selectedSettings[input.child.name],"items":input.child.options,"outlined":"","dense":"","hide-details":"","disabled":_vm.readOnly},on:{"input":function($event){return _vm.updateSelectedSettings($event, input.child)}}}):(input.child && input.child.name)?_c('span',{staticClass:"red-text"},[_vm._v(" "+_vm._s(input.child.name)+" is undefined. ")]):_vm._e()],1):_vm._e()])])})],2)}),0):_vm._e()])}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }