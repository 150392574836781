var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scenario-settings-types-switch px-3"},[_c('div',{staticClass:"d-flex align-center justify-space-between mb-5 toggle-container pa-1"},[_c('div',{class:[
        'cursor-pointer toggle-btn py-1 px-5',
        _vm.selectedScenarioSettingsType === 'planningControlCategories'
          ? 'active'
          : ''
      ],on:{"click":_vm.showPlanningControl}},[_vm._v(" Planning control ")]),_c('div',{class:[
        'cursor-pointer toggle-btn py-1 px-5',
        _vm.selectedScenarioSettingsType === 'developmentCostsCategories'
          ? 'active'
          : ''
      ],on:{"click":_vm.showDevelopmentCosts}},[_vm._v(" Development costs ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }